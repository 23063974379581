import React from "react"
import { Container, Header, Footer } from "../components/Lib"
import SpecialPageHeader from "../components/SpecialPageHeader"
import { ReactComponent as SponsorshipSVG } from "../assets/images/sponsorship.svg"
import { ReactComponent as SponsorshipServicesSVG } from "../assets/images/sponsershipServices.svg"
import { ReactComponent as SponsorArvan } from "../assets/images/sponser-arvan.svg"
import { ReactComponent as SponsorMilkan } from "../assets/images/sponser-milkan.svg"
import { ReactComponent as SponsorNovin } from "../assets/images/sponser-novin.svg"
import { ReactComponent as SponsorMymi } from "../assets/images/sponsor-mymi-sq.svg"
import SEO from "../components/seo"

const SpecialPageSection = ({ title, text, figure, type }) => {
  return (
    <div
      className={`special_page__section${
        type ? ` special_page__section--${type}` : ""
      }`}
    >
      <div className="special_page__section__information">
        <h3>{title}</h3>
        <p dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>
      <div className="special_page__section__figure">{figure}</div>
    </div>
  )
}

const About = () => {
  return (
    <Container>
      <SEO
        title="حمایت مالی"
        description="از پادکست فارسی بایوکست حمایت کنید. ما در بایوکست زندگینامه افراد موفق رو تعریف می‌کنیم."
        keywords={["بایوکست", "پادکست زندگینامه", "زندگینامه افراد موفق"]}
      />
      <Header className="header--lightgray" />
      <section className="special_page">
        <SpecialPageHeader
          title="Sponsorship"
          subtitle="حامی مالی بایوکست شوید"
        />
        <SpecialPageSection
          type="column"
          title="به عنوان شنونده چطور از بایوکست پشتیبانی کنم؟"
          text={`
          بهترین راه حمایت از محتوای خوب کمک به انتشار هر چه بیشتر آن است. اگر از محتوای بایوکست لذت می‌برید بهترین حمایت شما از ما اشتراک گذاری آن با دوستانتان است
          <br />
          <br />
          شنیدن بایوکست همیشه رایگان بوده و خواهد بود اگر با همه این تفاسیر باز هم مایل به حمایت مالی از بایوکست هستید می‌توانید از طریق لینک زیر مبلغ مورد نظر خود را برای ما بفرستید. کمک‌های شما بیشتر از جنبه مالی، حس ارزشمند بودن را به ما القا می‌کند و جدا از مبلغ آن برای ما دلگرمیست.
          <br />
          <br />
          <a
          class="link button button--primary"
          target="_blank"
          href="https://hamibash.com/biocastpodcast"
        >
         حمایت مالی از بایوکست
        </a>
          `}
        />
        <SpecialPageSection
          title="آیا بایوکست مناسب کسب‌و‌کار من است؟"
          text={`
          در تابستان ۹۹ هر اپیزود پادکست بایوکست دست‌کم ۱۵ هزار بار از اپلیکیشن‌های پادکست دانلود شده. البته گروهی هم پادکست را روی تلگرام و جاهای دیگر گوش می‌کنند که در این آمار نیامده.اگر می‌خواهید اسپانسر پادکست بایوکست بشوید لطفاً یک ایمیل به <a class="link" href="mailto:sponsor@biocastpodcast.ir">sponsor@biocastpodcast.ir</a> بزنید و محصول یا برندی را که می‌خواهید در پادکست تبلیغ کنید معرفی کنید.
          <br /><br /> ما آماری از گزارش تحلیلی از شنوندگانمان برایتان ارسال می‌کنیم درصورتی که احساس کردید حمایت از بایوکست برای شما مفید خواهد بود،‌ مشتاق همکاری با شما هستیم.
          `}
          figure={<SponsorshipSVG />}
        />
        <SpecialPageSection
          type="reverse"
          title="چه خدماتی به عنوان حامی‌مالی دریافت می‌کنم؟"
          text={`محصول یا خدمات شما بصورت کامل توسط گوینده پادکست برای مخاطب توضیح داده خواهد شد، لینک محصول یا صفحه فرود مورد نظر شما بخش توضیحات تمام اپلیکیشن های پادکستی که بایوکست در آن منتشر می‌شود قرار می‌گیرد.<br />
          توضیحات محصول و لینک صفحه فرود شما در صفحه اپیزود وبسایت بایوکست بصورت مادام العمر قرار می‌گیرد. 
          <br />علاوه بر این می‌توانید از خدماتی مثل نوشتن پست مهمان در بلاگ بایوکست، تبلیغ محصول یا خدمات در خبرنامه ایمیلی بایوکست، پست مهمان در شبکه های اجتماعی بایوکست داشته باشید.`}
          figure={<SponsorshipServicesSVG />}
        />
        <SpecialPageSection
          type="column"
          title="چه برند هایی تا به حال حامی بایوکست شده‌اند؟"
          text={`تاکنون برند های پیشرویی مثل بارجیل، خنیاگر و نشر نوین حامی بایوکست شده‌اند و از خدمات تبلیغاتی ما استفاده کرده اند.`}
          figure={
            <div class="special_page__grid">
              <div class="special_page__sponsor_logo">
                <a
                  className="link navigator"
                  href="https://nashrenovin.ir/?ref=20"
                  target="_blank"
                >
                  <SponsorNovin />
                </a>
              </div>
              <div class="special_page__sponsor_logo">
                <a
                  className="link navigator"
                  href="https://myxiaomi.ir/"
                  target="_blank"
                >
                  <SponsorMymi />
                </a>
              </div>
            </div>
          }
        />
      </section>
      <Footer beforeColor="white" />
    </Container>
  )
}

export default About
